import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f37521",
      color: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
    },
    header: {
      backgroundColor: "#92E7A3",
      logoColor: "#92E7A3",
      buttonColor: "#92E7A3",
      fontColor: "#0039a5",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
  },
  MuiButton: {
    root: {
      color: "#ffffff",
      backgroundColor: "#f37521",
    },
    contained: {
      color: "#ffffff",
      backgroundColor: "#f37521",
    },
  },
  Button: {
    root: {
      color: "#ffffff",
      backgroundColor: "#f37521",
    },
  },
});

export default theme;
