import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../resources/02-logo-recargalejos.png";

const ExportButtons = ({ transactionDetails }) => {
  const [open, setOpen] = React.useState(false);
  const handleGeneratePDF = () => {
    const doc = new jsPDF();

    // Add image as a header
    doc.addImage(logo, "PNG", 50, 0, 100, 100);
    // Agregar un título al documento
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("¡Gracias por usar Recargalejos!", 50, 60);

    // Crear una tabla para mostrar los detalles de la transacción
    const tableColumns = ["Detalle", ""];
    const tableRows = Object.entries(transactionDetails).map(([key, value]) => [
      key,
      value !== null && value !== undefined ? value.toString() : "",
    ]);

    // Agregar un título a la tabla de detalles
    doc.setFontSize(14);
    doc.text("Detalle de la transacción:", 10, 70);

    // Crear la tabla de detalles
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 80, // Ajustar la posición vertical de la tabla
    });

    doc.save(
      "detalle_transaccion_" +
      transactionDetails["Fecha transacción"] || transactionDetails["requestDate"] +
      ".pdf"
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        type="submit"
        sx={{ color: "#ffffff" }}
        onClick={handleClickOpen}
      >
        Exportar PDF
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Exportar PDF</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Deseas generar un PDF con los detalles de la transacción?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            type="submit"
            sx={{ color: "#ffffff" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleGeneratePDF}
            color="primary"
            variant="contained"
            type="submit"
            sx={{ color: "#ffffff" }}
          >
            Generar PDF
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExportButtons;
