import { request } from "graphql-request";
import { useQuery, useMutation } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Stack } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import {
  Divider,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  QUERY_GET_INITIAL_DATA,
  QUERY_VALIDATE_ONLY_SEND_TRANSFER,
  QUERY_GET_DATA_TRANSFER,
} from "../gql/queries";
import { MUTATION_CREATE_FLOW_TRANSACTION } from "../gql/mutations";
import CardMedia from "@mui/material/CardMedia";
import logo from "../resources/02-logo-recargalejos.png";
import logoFlow from "../resources/logo-flow.jpg";

const RecargaForm = () => {
  const [amount, setAmount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [debouncedAmount, setDebouncedAmount] = useState("");
  const [formData, setFormData] = useState({});
  const [hasAmountError, setHasAmountError] = useState(false);
  const [hasPhoneError, setHasPhoneError] = useState(false);
  const [phone, setPhone] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [dataFlow, setDataFlow] = useState({});
  const [dataToPost, setDataToPost] = useState({});
  const localCurrencyformatter = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const endPoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  const checkoutUrl = process.env.REACT_APP_BASE_URL + "/checkout";
  const confirmUrl = process.env.REACT_APP_BASE_URL + "/confirm";
  const confirmView =
    process.env.REACT_APP_DJANGO_CONFIRM_VIEW ||
    "https://recargalejos.cl:8080/confirm_view";
  let session_id = Math.floor(Math.random() * 10000000000 + 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    // validate amount and phone number
    let maxAmount = data.initialDataForTransaction.maxAmount;
    let minAmount = data.initialDataForTransaction.minAmount;
    let currentAmount = parseFloat(formData.amount);
    if (
      formData.amount &&
      formData.phoneNumber &&
      currentAmount > 0 &&
      currentAmount <= maxAmount &&
      currentAmount >= minAmount
    ) {
      setPhone(formData.phoneNumber);
      setAmount(formData.amount);
      let nameOperator = data.initialDataForTransaction.operatorName;
      let codeOperator = dataQuery.validateOnlySendTransfer.distributorRef;
      setOperatorName(nameOperator);
      let foreign_amount = parseFloat(
        dataQuery.validateOnlySendTransfer.receiveValue
      );
      let foreign_currency =
        dataQuery.validateOnlySendTransfer.receiveCurrencyIso;
      let fee = dataTransfer?.getDataTransferByPhone?.commission || 0;
      let destiny_country =
        dataTransfer?.getDataTransferByPhone?.regionCode || "Error";
      setDataToPost({
        amount: currentAmount,
        session_id: session_id.toString(),
        return_url: checkoutUrl,
        foreign_amount: foreign_amount,
        foreign_currency: foreign_currency,
        phone_number: formData.phoneNumber,
        description:
          "Recarga de celular por " +
          formData.amount +
          " USD, teléfono: " +
          formData.phoneNumber +
          ".",
        fee: fee,
        destiny_country: destiny_country,
        selected_dollar_amount: currentAmount,
        company_name: { nameOperator },
        company_code: codeOperator,
      });
      mutateFlow({
        amount: currentAmount,
        description: "Recarga de celular por " + formData.amount + " USD.",
        destiny_country: destiny_country,
        fee: dataTransfer?.getDataTransferByPhone?.commission || 0,
        foreign_amount: foreign_amount || 0,
        foreign_currency: foreign_currency || "USD",
        phone_number: formData.phoneNumber,
        url_return: confirmView,
        url_confirmation: confirmUrl,
        subject: "Recarga de celular por " + formData.amount + " USD.",
        token: session_id.toString(),
        selected_dollar_amount: currentAmount,
      });
      setShowSummary(true);
    } else {
      if (
        !formData.amount ||
        currentAmount <= minAmount ||
        currentAmount > maxAmount
      ) {
        setHasAmountError(true);
      } else if (!formData.phoneNumber) {
        setHasPhoneError(true);
      } else {
        alert("Por favor revisa los datos ingresados.");
      }
    }
  };

  const fetchData = async (phoneNumber) => {
    const queryString = QUERY_GET_INITIAL_DATA;
    const data = await request(endPoint, queryString, { phoneNumber });
    return data;
  };

  // Debounce the search term to prevent rapid API calls
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  const { data, status } = useQuery(
    [debouncedSearchTerm],
    () => fetchData(debouncedSearchTerm),
    {
      enabled: debouncedSearchTerm !== "",
    }
  );

  const fetchValidateOnlyData = async () => {
    if (debouncedAmount !== "" && debouncedSearchTerm !== "") {
      const data = await request(endPoint, QUERY_VALIDATE_ONLY_SEND_TRANSFER, {
        phone: debouncedSearchTerm,
        amount: debouncedAmount,
      });
      return data;
    }
  };

  const { data: dataQuery, status: dataQueryStatus } = useQuery(
    [debouncedSearchTerm, debouncedAmount, "dataQuery"],
    () => fetchValidateOnlyData(phone, amount),
    {
      refetchOnWindowFocus: false,
      enabled: debouncedSearchTerm !== "" && debouncedAmount !== "",
    }
  );

  const fetchGetDataTransfer = async (phone) => {
    if (debouncedSearchTerm !== "") {
      const data = await request(endPoint, QUERY_GET_DATA_TRANSFER, {
        phoneNumber: phone,
      });
      return data;
    }
  };

  const { data: dataTransfer } = useQuery(
    [phone, "dataTransfer"],
    () => fetchGetDataTransfer(phone),
    {
      refetchOnWindowFocus: false,
      enabled: debouncedSearchTerm !== "",
    }
  );

  const fetchCreateFlowTransaction = (data) => {
    const dataMutation = request(endPoint, MUTATION_CREATE_FLOW_TRANSACTION, {
      amount: data.amount,
      description: data.description,
      destiny_country: data.destiny_country,
      fee: data.fee,
      foreign_amount: data.foreign_amount,
      foreign_currency: data.foreign_currency,
      phone_number: data.phone_number,
      url_return: confirmView,
      url_confirmation: confirmUrl,
      subject: data.description,
      token: data.session_id,
      selected_dollar_amount: data.selected_dollar_amount,
    });
    return dataMutation;
  };
  const { mutate: mutateFlow } = useMutation(
    (data) => fetchCreateFlowTransaction(data),
    {
      onSuccess: (data) => {
        setDataFlow(data);
        setPaymentStatus(true);
      },
      onError: (error) => {
        console.log("[mutateFlow]error: ", error);
      },
    }
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
      px={isMobile ? 2 : 0}
    >
      <Box
        maxWidth={isMobile ? "100%" : 400}
        width="100%"
        padding={isMobile ? 2 : 10}
        border="1px solid #ccc"
        borderRadius={5}
      >
        {showSummary ? (
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <Typography variant="h6" align="center" gutterBottom>
                        Detalles de la recarga
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Operador</TableCell>
                    <TableCell align="center">{operatorName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Teléfono</TableCell>
                    <TableCell align="center">+{phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Monto ingresado</TableCell>
                    <TableCell align="center">{amount} USD</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell align="center">
                      {new Date().toLocaleString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total a pagar en CLP</TableCell>
                    <TableCell align="center">
                      {paymentStatus ? (
                        localCurrencyformatter.format(
                          dataFlow?.InitFlowTransaction?.totalAmount || 0
                        )
                      ) : (
                        <CircularProgress />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {paymentStatus ? (
              <Box
                mt={2}
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                justifyContent="center"
                alignItems="center"
              >
                {paymentStatus &&
                  dataFlow?.InitFlowTransaction?.totalAmount > 0 && (
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ color: "#ffffff", mb: isMobile ? 2 : 0 }}
                      startIcon={
                        <Avatar
                          src={logoFlow}
                          sx={{ width: 100, height: 30 }}
                          variant="square"
                        />
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setDataToPost({
                          ...dataToPost,
                          totalamount:
                            dataFlow?.InitFlowTransaction?.totalAmount || 0,
                        });
                        localStorage.setItem(
                          "dataPosted",
                          JSON.stringify(dataToPost)
                        );
                        localStorage.setItem(
                          "token",
                          dataFlow?.InitFlowTransaction?.token
                        );
                        // redirect to flow
                        window.location.href =
                          dataFlow?.InitFlowTransaction?.url +
                          "?token=" +
                          dataFlow?.InitFlowTransaction?.token;
                      }}
                      fullWidth={isMobile}
                    >
                      Pagar con Flow
                    </Button>
                  )}
                <Button
                  variant="contained"
                  sx={{ ml: isMobile ? 0 : 2, color: "#ffffff" }}
                  onClick={() => {
                    setShowSummary(false);
                    setPaymentStatus(false);
                    setDataFlow({});
                    setPhone("");
                    localStorage.removeItem("dataPosted");
                    localStorage.removeItem("webpayToken");
                  }}
                  href="/"
                  fullWidth={isMobile}
                >
                  Volver
                </Button>
              </Box>
            ) : (
              <CircularProgress />
            )}
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              <CardMedia
                component="img"
                src={logo}
                alt="logo"
                sx={{ width: "100%", maxWidth: 400, height: "100px" }}
              />
            </Box>
            <Typography variant={isMobile ? "h5" : "h4"} align="center">
              ¿Más rápido? ¡Imposible!
            </Typography>
            <Typography variant="subtitle2" align="left" sx={{ mt: 2 }}>
              Recargalejos es la forma más rápida y segura de recargar un
              celular prepago desde Chile. Puedes recargar tu propio celular o
              el de un amigo en todo latinoamérica. <br></br> Sólo necesitas el
              número de teléfono y el monto a recargar. ¡Así de fácil!
            </Typography>
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: 5,
              }}
            >
              <Box
                sx={{
                  "& .MuiTextField-root": {
                    m: 1,
                    width: isMobile ? "100%" : "50ch",
                  },
                }}
              >
                <Grid item xs={12}>
                  <TextField
                    id="phoneNumber"
                    label="Número de teléfono"
                    helperText={
                      hasPhoneError
                        ? "Por favor ingrese un número válido."
                        : "Por ejemplo: 56961201785"
                    }
                    type="tel"
                    value={searchTerm}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\+/g, "");
                      setSearchTerm(sanitizedValue);
                      setFormData({ ...formData, phoneNumber: sanitizedValue });
                    }}
                    required
                    error={hasPhoneError}
                    fullWidth
                  />
                </Grid>
                {debouncedSearchTerm !== "" && status === "loading" && (
                  <Stack alignItems="center">
                    <CircularProgress />
                  </Stack>
                )}
                {status === "error" && (
                  <Stack alignItems="center">
                    <CircularProgress color="error" />
                  </Stack>
                )}
                {data?.initialDataForTransaction?.minAmount !== null &&
                  status === "success" && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          id="amount"
                          label={
                            "Monto en " +
                            data.initialDataForTransaction.currencyIso
                          }
                          helperText={
                            hasAmountError
                              ? "Por favor ingrese un monto válido."
                              : `Mínimo ${data.initialDataForTransaction.minAmount} - Máximo ${data.initialDataForTransaction.maxAmount}`
                          }
                          InputProps={{
                            inputProps: {
                              min: parseInt(
                                data.initialDataForTransaction.minAmount || 0
                              ),
                              max: parseInt(
                                data.initialDataForTransaction.maxAmount || 0
                              ),
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          type="decimal"
                          onChange={(e) => {
                            setDebouncedAmount(e.target.value);
                            setFormData({
                              ...formData,
                              amount: e.target.value,
                              operatorName:
                                data.initialDataForTransaction.operatorName,
                            });
                          }}
                          error={hasAmountError}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            my: 2,
                          }}
                        >
                          <img
                            src={data.initialDataForTransaction.logoUrl}
                            alt="Logo"
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Divider sx={{ width: "100%", my: 2 }} />
                      {dataQueryStatus !== "success" && (
                        <Stack alignItems="center" color="gray">
                          <CircularProgress sx={{ color: "gray" }} />
                        </Stack>
                      )}
                      {dataQueryStatus === "success" && (
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            mt: 2,
                            color: "#ffffff",
                            width: isMobile ? "100%" : "auto",
                          }}
                        >
                          Siguiente
                        </Button>
                      )}
                    </>
                  )}
                {data?.initialDataForTransaction?.minAmount === null &&
                  status === "success" && (
                    <Typography
                      variant="subtitle2"
                      align="center"
                      sx={{ mt: 2, color: "red", fontWeight: "bold" }}
                    >
                      No se encontró información para el número ingresado.
                    </Typography>
                  )}
              </Box>
            </Grid>
          </form>
        )}
      </Box>
    </Box>
  );
};

export default RecargaForm;
