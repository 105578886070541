import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AppBar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Toolbar,
  Typography,
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

const useStyles = styled((theme) => ({
  appBar: {
    backgroundColor: "f37521", // Cambia el color de fondo de la app bar según tus necesidades
  },
  logo: {
    maxWidth: "100px", // Cambia el tamaño máximo del logo según tus necesidades
    height: "auto",
    marginRight: theme.spacing(2), // Espacio de margen a la derecha del logo
  },
  title: {
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(1), // Espacio de margen entre los botones
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
}));

const Header = () => {
  const classes = useStyles();
  const [openHelp, setOpenHelp] = useState(false);
  const [openFaq, setOpenFaq] = useState(false);

  const handleCloseAyuda = () => {
    setOpenHelp(false);
  };

  const handleOpenFaq = () => {
    setOpenFaq(true);
  };

  const handleCloseFaq = () => {
    setOpenFaq(false);
  };

  const handleClickMail = () => {
    const emailAddress = 'contacto@recargalejos.cl';
    const subject = 'Error en recarga';
    const body = 'Hola, \n\n necesito ayuda con mi recarga. \n\n Saludos';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography href="/" variant="h6" component="div" sx={{ flexGrow: 1, color: "white" }}>
            Recarga Lejos
          </Typography>
          <div className={classes.title}></div>
          <Button href="/" sx={{ color: "white", fontWeight: "bold"}}>Home</Button>
          <Button sx={{ color: "white", fontWeight: "bold"}} onClick={handleOpenFaq}>
            FAQ
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={openHelp}
        onClose={handleCloseAyuda}
        fullWidth
        maxWidth="sm"
        aria-labelledby="dialog-title"
      >
        <div className={classes.dialogContent}>
          <DialogTitle className="dialog-title">Ayuda</DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1">
              Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
              dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
              ac consectetur ac, vestibulum at eros. Aenean lacinia bibendum
              nulla sed consectetur. Praesent commodo cursus magna, vel
              scelerisque nisl consectetur et. Donec sed odio dui. Donec
              ullamcorper nulla non metus auctor fringilla.
              <br />
              Contactarse con Diego Guzman
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseAyuda}>
              Exito
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <Dialog
        open={openFaq}
        onClose={handleCloseFaq}
        maxWidth="sm"
        aria-labelledby="dialog-title"
        className="my-dialog"
        PaperProps={{ style: { borderRadius: "8px" } }}
      ></Dialog>

      <Dialog
        open={openFaq}
        onClose={handleCloseFaq}
        maxWidth="sm"
        aria-labelledby="dialog-title"
        className="my-dialog"
        PaperProps={{ style: { borderRadius: "8px" } }}
      >
        <div className={classes.dialogContent}>
          <DialogTitle className="dialog-title">
            Preguntas Frecuentes
          </DialogTitle>
          <DialogContent dividers className="dialog-content">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>¿Qué es Recarga Lejos?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Recarga Lejos es una plataforma que te permite hacer recargas
                  a celulares de cualquier operador en Chile
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>¿Cómo puedo hacer una recarga?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Para hacer tu recarga deberás completar el formulario de www.recargalejos.cl, ingresar correctamente tus datos, numero de teléfono a recargar, el operador, monto a recargar y dar clic al botón RECARGAR. Al realizar el pago, la transacción se llevará a cabo y recibirás tu comprobante de recarga exitosa en unos minutos.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>¿Cómo puedo pagar?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Recargalejos.cl utiliza sistema de pago webpay, es decir, puedes realizar pagos utilizando tanto tarjetas de crédito como débito de diferentes entidades bancarias incluido la Cuenta Rut de Banco Estado.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>¿Qué servicios puedo recargar?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  En Recargalejos.cl podrás recargar teléfonos celulares de países extranjeros desde Chile.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>¿Recargué y no llegó el saldo a al número recargado?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <span>Si tu transacción no fue exitosa y se descontó el saldo de tu cuenta bancaria, te recomendamos que te comuniques directamente con nosotros </span>{' '}
                  <Link component="button" variant="body2" style={{ fontWeight: 'bold', marginRight: '5px' }} onClick={handleClickMail}>
                    Aqui
                  </Link>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  ¿Puedo hacer una recarga a cualquier operador?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lamentablemente,  no se pueden hacer anulaciones ni devoluciones de recargas. Al ser mediadores de pago no tenemos acceso a las compañías de telecomunicaciones, por lo que no podemos anular una recarga que ya se pagó exitosamente. Te pedimos que verifiques tus datos de recarga antes de pagar.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseFaq} color="primary">
              Salir
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default Header;
