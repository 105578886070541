import { gql } from "graphql-request";

// All queries are defined here
export const QUERY_GET_LOGO = gql`
  query GetLogoByPhoneNumber($phoneNumber: String!) {
    getLogoByPhone(phone: $phoneNumber) {
      logoUrl
    }
  }
`;

export const QUERY_ESTIMATE_PRICES = gql`
  query EstimatePrices($phoneNumber: String!, $amount: Float!) {
    estimatePrices(phone: $phoneNumber, amountInUsd: $amount) {
      localAmount
      localCurrency
      dolarAmount
    }
  }
`;

export const QUERY_GET_DATA_TRANSFER = gql`
  query GetDataTransfer($phoneNumber: String!) {
    getDataTransferByPhone(phone: $phoneNumber) {
      skuCode
      uatNumber
      commission
      regionCode
      localizationKey
      processingMode
      maxAmount
      minAmount
      currencyIso
    }
  }
`;

export const QUERY_SEND_TRANSFER = gql`
  query SendTransfer($phoneNumber: String!, $amount: String!) {
    sendTransfer(phone: $phoneNumber, amountInUsd: $amount) {
      transferId
      skuCode
      customerFee
      distributorFee
      receiveValue
      receiveCurrencyIso
      taxRate
      comissionApplied
      processingState
      distributorRef
    }
  }
`;

export const QUERY_VALIDATE_ONLY_SEND_TRANSFER = gql`
  query ValidateOnlySendTransfer($phone: String!, $amount: String!) {
    validateOnlySendTransfer(phone: $phone, amountInUsd: $amount) {
      transferId
      skuCode
      customerFee
      distributorFee
      receiveValue
      receiveCurrencyIso
      taxRate
      comissionApplied
      processingState
      distributorRef
    }
  }
`;

export const QUERY_GET_INITIAL_DATA = gql`
  query getInitialDataForTransaction($phoneNumber: String!) {
    initialDataForTransaction(phone: $phoneNumber) {
      minAmount
      maxAmount
      currencyIso
      logoUrl
      operatorName
    }
  }
`;
