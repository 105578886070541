import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Core_Theme from "../core/Theme.jsx";//Importacion de tema personalizado
import Header from './Header'; // Importa el componente del header
import FormularioRecarga from './FormRecarga'; // Importa el componente del formulario de recarga
import ConfirmFlowTransaction from './ConfirmFlow.jsx';
import Footer from './Footer'; // Importa el componente del footer
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const MiComponente = () => {
    return (
        <BrowserRouter>
            <ThemeProvider theme={Core_Theme}>
            <Header />
            <Routes>
                <Route path="/" element={<FormularioRecarga />} />
                <Route path="/confirm" element={<ConfirmFlowTransaction />} />
            </Routes>
            <Footer />
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default MiComponente;
