import { gql } from "graphql-request";

export const MUTATION_CREATE_WEBPAY_TRANSACTION = gql`
  mutation initPaymentMutation(
    $amount: Float
    $session_id: String
    $return_url: String
    $foreign_amount: Float
    $foreign_currency: String
    $phone_number: String
    $description: String
    $fee: Float
    $destiny_country: String
    $selected_dollar_amount: Float
  ) {
    InitWebPayMutation(
      amount: $amount
      returnUrl: $return_url
      sessionId: $session_id
      foreignAmount: $foreign_amount
      foreignCurrency: $foreign_currency
      phoneNumber: $phone_number
      description: $description
      fee: $fee
      destinyCountry: $destiny_country
      selectedDollarAmount: $selected_dollar_amount
    ) {
      token
      url
      totalAmount
    }
  }
`;

export const MUTATION_CREATE_FLOW_TRANSACTION = gql`
  mutation initFlowPayment(
    $amount: Float
    $description: String
    $destiny_country: String
    $foreign_amount: Float
    $fee: Float
    $foreign_currency: String
    $phone_number: String
    $selected_dollar_amount: Float
    $subject: String
    $token: String
    $url_confirmation: String
    $url_return: String
  ) {
    InitFlowTransaction(
      amount: $amount
      description: $description
      destinyCountry: $destiny_country
      foreignAmount: $foreign_amount
      fee: $fee
      foreignCurrency: $foreign_currency
      phoneNumber: $phone_number
      selectedDollarAmount: $selected_dollar_amount
      subject: $subject
      token: $token
      urlConfirmation: $url_confirmation
      urlReturn: $url_return
    ) {
      flowOrder
      token
      url
      totalAmount
    }
  }
`;

export const MUTATION_COMMIT_WEBPAY_TRANSACTION = gql`
  mutation commitWebpayPayment($token: String!) {
    CommitWebPayMutation(token: $token) {
      accountingDate
      buyOrder
      cardDetail
      sessionId
      transactionDate
      vci
      amount
      status
      authorizationCode
      paymentTypeCode
      responseCode
      installmentsAmount
      installmentsNumber
      balance
    }
  }
`;

export const MUTATION_CONFIRM_FLOW_TRANSACTION = gql`
  mutation confirmFlowTransaction($token: String!) {
    ConfirmFlowTransaction(token: $token) {
      success
      message
    }
  }
`;
