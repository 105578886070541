import React from 'react';
import { Container, Grid, IconButton, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  return (
    <Container maxWidth="md" component="footer" sx={{ mt: 'auto' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            © {new Date().getFullYear()} Three Labs. Todos los derechos reservados.
          </Typography>
        </Grid>
        <Grid item>
          <IconButton href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </IconButton>
          <IconButton href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
            <TwitterIcon />
          </IconButton>
          <IconButton href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </IconButton>
          <IconButton href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
