import React from "react";
import { request } from "graphql-request";
import { useMutation } from "@tanstack/react-query";
import { MUTATION_CONFIRM_FLOW_TRANSACTION } from "../gql/mutations";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ExportButtons from "./ExportButtons";

const PaymentConfirmationComponent = () => {
  const endPoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  const token = localStorage.getItem("token");
  const fetchPaymentStatus = (data) => {
    const dataPaymentStatus = request(
      endPoint,
      MUTATION_CONFIRM_FLOW_TRANSACTION,
      data
    );
    return dataPaymentStatus;
  };
  const status_mapping = {
    2: "A",
    1: "P",
    3: "R",
    4: "RE",
  };
  let returned_data = {};
  let parsedMessage;
  const {
    mutate,
    status,
    isLoading,
    error,
    data: flowResponse,
  } = useMutation((data) => fetchPaymentStatus(data), {
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    const data = {
      token: token,
    };
    mutate(data);
  }, [mutate, token]);
  if (status === "success") {
    if (returned_data !== "Error al obtener datos" || returned_data !== null) {
      let message = flowResponse?.ConfirmFlowTransaction?.message
        .replace(/None/g, "null")
        .replace(/'/g, '"');
      parsedMessage = JSON.parse(message);
    } else {
      console.log("Error: ", returned_data);
    }
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
    >
      <Box
        maxWidth={400}
        width="10000%"
        padding={10}
        border="1px solid #ccc"
        borderRadius={5}
      >
        <Box mt={2}>
          {isLoading && <CircularProgress />}
          {error && (
            <Typography variant="h5" component="h5" gutterBottom>
              {error}
            </Typography>
          )}
          {status === "success" && (
            <Box>
              {parsedMessage?.status === 2 ? (
                <>
                  <Typography variant="h5" component="h5" gutterBottom>
                    ¡Recarga exitosa!
                  </Typography>
                  <Typography variant="h6" component="h6" gutterBottom>
                    En breve recibirás un SMS con los detalles de tu recarga. En
                    caso de no recibirlo, por favor comunicate con nuestro
                    servicio de atención al cliente.
                  </Typography>
                </>
              ) : (
                <Typography variant="h5" component="h5" gutterBottom>
                  Error: {status_mapping[parsedMessage?.status]}
                </Typography>
              )}
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>ID Flow</TableCell>
                      <TableCell>{parsedMessage.flowOrder || 0}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ID Orden</TableCell>
                      <TableCell>{parsedMessage.commerceOrder}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Fecha</TableCell>
                      <TableCell>
                        {new Date(parsedMessage.requestDate).toLocaleString()}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Detalle de recarga</TableCell>
                      <TableCell>{parsedMessage.subject}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Monto</TableCell>
                      <TableCell>
                        {parsedMessage.currency} {parsedMessage.amount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Medio de pago</TableCell>
                      <TableCell>
                        {parsedMessage?.paymentData?.media || "No disponible"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Impuesto</TableCell>
                      <TableCell>
                        {parsedMessage?.paymentData?.taxes || 0}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={2}>
                <ExportButtons transactionDetails={parsedMessage} />
              </Box>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ marginTop: 2, color: "#ffffff" }}
                href="/"
              >
                Volver al inicio
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentConfirmationComponent;
